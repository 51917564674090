import React, { useState } from "react";

//import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from "swiper";

//import Swiper styles
import "swiper/swiper.min.css";
import "swiper/swiper-bundle.css";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowUpRightFromSquare,
  faArrowLeftLong,
  faArrowRightLong,
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import { faHandPointer } from "@fortawesome/free-regular-svg-icons";
import {
  faGithub,
  faYoutube,
  faKickstarter,
} from "@fortawesome/free-brands-svg-icons";

import useWindowDimensions from "../utils/useWindowDimensions.js";

import "../css/projects.css";
import lcmGames from "../images/projects/lcmGames.jpg";
import lcmWeb from "../images/projects/lcmWeb.jpg";
import sysifusGames from "../images/projects/sysifusGames.jpg";
import sysifusWeb from "../images/projects/sysifusWeb.jpg";
import domahubWeb from "../images/projects/domahubWeb.jpg";
import vendeluxWeb from "../images/projects/vendeluxWeb.jpg";

// function to build out the projects
let workBuilder = (curr, index, array) => {
  let allStack = curr.stack.map((c, i, a) => {
    return (
      <li key={`work_${index}_stack_${i}`} className="project__stack-item">
        {c}
      </li>
    );
  });

  return (
    <SwiperSlide key={`${index}`} className="project is-rounded-corner">
      <div className="projectDetails">
        <h3 className="projectName">
          <a
            target="_blank"
            rel="noreferrer noopener"
            className="link noline"
            href={curr.url}
          >
            {curr.name}
            <FontAwesomeIcon
              className="inlineFA"
              icon={faArrowUpRightFromSquare}
            />
          </a>
        </h3>
        <p className="project__subtitle">{curr.subtitle}</p>
        <div className="project__description">{curr.description}</div>
        {allStack.length > 0 && <ul className="project__stack">{allStack}</ul>}
        <div className="project__links">
          {curr.kickstarter && (
            <a
              target="_blank"
              rel="noreferrer noopener"
              href={curr.kickstarter}
              className="link link--icon"
            >
              <FontAwesomeIcon icon={faKickstarter} />
            </a>
          )}
          {curr.youtube && (
            <a
              target="_blank"
              rel="noreferrer noopener"
              href={curr.youtube}
              className="link link--icon"
            >
              <FontAwesomeIcon icon={faYoutube} />
            </a>
          )}
          {curr.github && (
            <a
              target="_blank"
              rel="noreferrer noopener"
              href={curr.github}
              className="link link--icon"
            >
              <FontAwesomeIcon icon={faGithub} />
            </a>
          )}
          {curr.url && !curr.nolinkicon && (
            <a
              target="_blank"
              rel="noreferrer noopener"
              href={curr.url}
              className="link link--icon"
            >
              <FontAwesomeIcon icon={faArrowUpRightFromSquare} />
            </a>
          )}
        </div>
      </div>
      {curr.image && (
        <img
          alt={`Project${curr.name}`}
          src={curr.image}
          className="projectImage"
        />
      )}
    </SwiperSlide>
  );
};

function Projects(props) {
  let profession = props.profession;
  let mobileWidthSize = 900;
  let maxWidthSize = 1250;
  let isGames = profession === "games";
  let gamesWorks = [
    {
      name: "Love, Career & Magic",
      subtitle: "Party game",
      description: (
        <>
          <p>
            A collaborative storytelling party game where mythical fantasy meets
            reality TV. Successfully funded on Kickstarter in March of 2024.
            Received the official "Project we love" badge from Kickstarter.
          </p>
          <div className="inlineSpan">
            <a
              target="_blank"
              rel="noreferrer noopener"
              className="link textDecoration noline"
              href="https://www.youtube.com/watch?v=7uT2GWSOfno"
            >
              Learn more about the game.
              <FontAwesomeIcon
                className="inlineFA"
                icon={faArrowUpRightFromSquare}
              />
            </a>
          </div>
          <div className="inlineSpan">
            <a
              target="_blank"
              rel="noreferrer noopener"
              className="link textDecoration noline"
              href="https://www.youtube.com/watch?v=iOS-3EU7xpE&list=PLSLy9oTFPgBYp0dmBjwxEpp7pwrsj7tql"
            >
              Watch professional voice actors play.
              <FontAwesomeIcon
                className="inlineFA"
                icon={faArrowUpRightFromSquare}
              />
            </a>
          </div>
        </>
      ),
      stack: [
        "Improv",
        "Story-telling",
        "Acting",
        "Roleplay",
        "TTRPG",
        "Card Game",
        "Party Game",
      ],
      url: "https://lovecareermagic.com",
      github: "https://github.com/pegasusnotunicorn/sharehome",
      youtube:
        "https://www.youtube.com/watch?v=iOS-3EU7xpE&list=PLSLy9oTFPgBYp0dmBjwxEpp7pwrsj7tql",
      kickstarter:
        "https://www.kickstarter.com/projects/pegasusgamesnyc/love-career-and-magic-a-mythical-reality-tv-party-game",
      image: lcmGames,
    },
    {
      name: "Welcome to Sysifus Corp",
      subtitle: "Board game",
      description: (
        <>
          <p>
            A corporate strategy game where you backstab your fellow coworkers
            in a cut-throat race to the promotion. Successfully funded on
            Kickstarter in March of 2021.
          </p>
          <div className="inlineSpan">
            <a
              target="_blank"
              rel="noreferrer noopener"
              className="link textDecoration noline"
              href="https://sysifuscorp.com/puzzle"
            >
              Try the interactive game demo.
              <FontAwesomeIcon
                className="inlineFA"
                icon={faArrowUpRightFromSquare}
              />
            </a>
          </div>
          <div className="inlineSpan">
            <a
              target="_blank"
              rel="noreferrer noopener"
              className="link textDecoration noline"
              href="https://imgur.com/gallery/Mr1ufq7"
            >
              Read some testimonials.
              <FontAwesomeIcon
                className="inlineFA"
                icon={faArrowUpRightFromSquare}
              />
            </a>
          </div>
        </>
      ),
      stack: [
        "Hand & Resource Management",
        "Card Chaining",
        "Take That",
        "Tile Placement",
        "Racing",
        "Board Game",
      ],
      url: "https://sysifuscorp.com",
      github: "https://github.com/pegasusnotunicorn/sysifuscorp",
      youtube: "https://www.youtube.com/shorts/OOGUH8D_7qI",
      kickstarter:
        "https://www.kickstarter.com/projects/pegasusgamesnyc/welcome-to-sysifus-corp-a-cut-throat-corporate-board-game",
      image: sysifusGames,
    },
  ];
  let webWorks = [
    {
      name: "Vendelux",
      subtitle: "Event intelligence SaaS company",
      description: (
        <>
          <p>
            Enhanced Vendelux by developing new features, reducing technical
            debt, improving codebase, and solving front-end challenges.
          </p>
        </>
      ),
      stack: [
        "React",
        "TypeScript",
        "JavaScript",
        "MUI",
        "SCSS",
        "CSS",
        "NodeJS",
      ],
      url: "https://vendelux.com",
      github: "https://github.com/wlee-vendelux",
      image: vendeluxWeb,
    },
    {
      name: "DomaHub",
      subtitle: (
        <>
          Domain name Saas company
          <br />
          (page takes a while to load)
        </>
      ),
      description: (
        <>
          <p>
            Domahub was a domain name marketplace where users can create sales
            pages for their domain names and rent them out for hours at a time.
          </p>
          <div className="inlineSpan">
            <a
              target="_blank"
              rel="noreferrer noopener"
              className="link textDecoration noline"
              href="https://medium.com/@domahub/the-end-of-a-journey-acquisition-announcement-dde604055ec3"
            >
              Acquired by Efty in 2019
              <FontAwesomeIcon
                className="inlineFA"
                icon={faArrowUpRightFromSquare}
              />
            </a>
          </div>
        </>
      ),
      stack: [
        "jQuery",
        "Express",
        "NodeJS",
        "MySQL",
        "Nginx",
        "LetsEncrypt",
        "Stripe Connect",
      ],
      url: "https://domahub.onrender.com/",
      github: "https://github.com/Xcellion/domahub",
      image: domahubWeb,
    },
    {
      name: "Welcome to Sysifus Corp",
      subtitle: "Official website + game demo",
      description: (
        <>
          <p>
            The official website for my cut-throat corporate board game. Built
            entirely from scratch using Jekyll and JavaScript.
          </p>
          <div className="inlineSpan">
            <a
              target="_blank"
              rel="noreferrer noopener"
              className="link textDecoration noline"
              href="https://sysifuscorp.com/puzzle"
            >
              Interactive game demo
              <FontAwesomeIcon
                className="inlineFA"
                icon={faArrowUpRightFromSquare}
              />
            </a>
          </div>
        </>
      ),
      stack: [
        "Jekyll",
        "Github Pages",
        "Stripe Checkout",
        "Vanilla JavaScript",
      ],
      url: "https://sysifuscorp.com",
      github: "https://github.com/pegasusnotunicorn/sysifuscorp",
      image: sysifusWeb,
    },
    {
      name: "Love, Career & Magic",
      subtitle: "Official website",
      description:
        "The official website for my reality TV card game. Built entirely from scratch using React and JavaScript.",
      stack: ["React", "Netlify", "GreenSock", "Netlify Functions"],
      url: "https://lovecareermagic.com",
      github: "https://github.com/pegasusnotunicorn/sharehome",
      image: lcmWeb,
    },
  ];

  //smaller side games
  let sideGames = [
    {
      name: "Race to the Raft Demo",
      subtitle: "Made with JavaScript + HTML",
      url: "https://thecityofkings.com/race-to-the-raft-demo/",
      nolinkicon: true,
      description: (
        <>
          <p>
            A puzzle game about helping cats escape from a burning island.
            Created for Frank West as an interactive web demo for the{" "}
            <a
              className="link noline"
              target="_blank"
              rel="noreferrer noopener"
              href="https://www.kickstarter.com/projects/tcokgame/race-to-the-raft"
            >
              Kickstarter campaign
            </a>
            .
          </p>
          <div className="inlineSpan">
            <a
              target="_blank"
              rel="noreferrer noopener"
              className="link textDecoration noline"
              href="https://thecityofkings.com/race-to-the-raft-demo/"
            >
              Play now in your browser!
              <FontAwesomeIcon
                className="inlineFA"
                icon={faArrowUpRightFromSquare}
              />
            </a>
          </div>
        </>
      ),
      stack: [],
    },
    {
      name: "4orner",
      subtitle: "Made with Phaser + JavaScript",
      url: "/game/4orner",
      nolinkicon: true,
      description: (
        <>
          <p>
            A game of color and collecting balls. Drag your ball into the
            corners before capturing other balls of the same color! Created as
            part of the{" "}
            <a
              className="link noline"
              target="_blank"
              rel="noreferrer noopener"
              href="https://1minlee.blogspot.com/search/label/30daydeveloper"
            >
              #30DayDev challenge
            </a>
            .
          </p>
          <div className="inlineSpan">
            <a
              target="_blank"
              rel="noreferrer noopener"
              className="link textDecoration noline"
              href="/game/4orner"
            >
              Play now in your browser!
              <FontAwesomeIcon
                className="inlineFA"
                icon={faArrowUpRightFromSquare}
              />
            </a>
          </div>
        </>
      ),
      stack: [],
    },
    {
      name: "4orner 3D",
      subtitle: "Made with Unity",
      url: "/game/4orner3d",
      nolinkicon: true,
      description: (
        <>
          <p>
            The remastered 3D version of the classic sleeper hit browser game,
            4orner! Created in a single day as my final project in the{" "}
            <a
              className="link noline"
              target="_blank"
              rel="noreferrer noopener"
              href="https://1minlee.blogspot.com/2017/03/unity-game-developer.html"
            >
              2017 PlayCrafting NYC Unity class
            </a>
            .
          </p>
          <div className="inlineSpan">
            <a
              target="_blank"
              rel="noreferrer noopener"
              className="link textDecoration noline"
              href="/game/4orner3d"
            >
              Play now in your browser!
              <FontAwesomeIcon
                className="inlineFA"
                icon={faArrowUpRightFromSquare}
              />
            </a>
            <p className="tooltip">(not mobile friendly)</p>
          </div>
        </>
      ),
      stack: [],
    },
    {
      name: "War of Shapes",
      subtitle: "Made with JavaScript + Socket.io",
      url: "/game/wos/",
      nolinkicon: true,
      description: (
        <>
          <p>
            A game of killing other colored balls in space. WASD to move. Space
            to dash and kill. Click to nullify oncoming attacks.
          </p>
          <div className="inlineSpan">
            <a
              target="_blank"
              rel="noreferrer noopener"
              className="link textDecoration noline"
              href="/game/wos/"
            >
              Single player game
              <FontAwesomeIcon
                className="inlineFA"
                icon={faArrowUpRightFromSquare}
              />
            </a>
          </div>
          <div className="inlineSpan">
            <a
              target="_blank"
              rel="noreferrer noopener"
              className="link textDecoration noline"
              href="https://wos.herokuapp.com/"
            >
              Multi player room (no killing)
              <FontAwesomeIcon
                className="inlineFA"
                icon={faArrowUpRightFromSquare}
              />
            </a>
            <p className="tooltip">
              (not mobile friendly, page takes a while to load)
            </p>
          </div>
        </>
      ),
      stack: [],
    },
  ];

  let [works] = useState(profession === "games" ? gamesWorks : webWorks);

  //all projects and side projects
  let allWorks = works.map(workBuilder);
  let allSideGames = sideGames.map(workBuilder);
  const { width } = useWindowDimensions();

  //slides per view for projects
  let slidesPerView;
  if (width <= mobileWidthSize) slidesPerView = 1;
  else if (width <= maxWidthSize) slidesPerView = 2;
  else slidesPerView = isGames ? 2 : 3;

  //slides per view for side projects
  let slidesPerViewSideProjects;
  if (width <= mobileWidthSize) slidesPerViewSideProjects = 1;
  else if (width <= maxWidthSize) slidesPerViewSideProjects = 2;
  else slidesPerViewSideProjects = 3;

  //properties of the swiper
  let swiperProps = {
    modules: [Navigation, Pagination],
    loop: false,
    spaceBetween: 50,
    pagination: {
      type: "bullets",
      clickable: true,
    },
  };

  return (
    <div className="projectSection mainBody row">
      <section id="projects" className="section projects">
        <h2 className="section__title">
          {isGames ? "Tabletop Games" : "Works"}
          {((isGames && slidesPerView === 1) ||
            (!isGames && slidesPerView < 3)) && (
            <>
              <br />
              <span className="project__subtitle">
                <span className="swiperIcon">
                  <FontAwesomeIcon
                    className="swiperIconArrow"
                    icon={faArrowLeftLong}
                  />
                  <FontAwesomeIcon className="" icon={faHandPointer} />
                  <FontAwesomeIcon
                    className="swiperIconArrow"
                    icon={faArrowRightLong}
                  />
                </span>
                <span>swipe to see more</span>
              </span>
            </>
          )}
        </h2>
        <div className="customNavigationWrapper">
          <FontAwesomeIcon
            id="worksPrev"
            className="customNavigationButton"
            icon={faChevronLeft}
          />
          <FontAwesomeIcon
            id="worksNext"
            className="customNavigationButton"
            icon={faChevronRight}
          />
        </div>
        <Swiper
          {...swiperProps}
          key={`works${allWorks.length}`}
          className="worksSwiper swiperSection projects__grid"
          slidesPerView={slidesPerView}
          navigation={{
            prevEl: "#worksPrev",
            nextEl: "#worksNext",
          }}
        >
          {allWorks}
        </Swiper>

        <h2 className="section__title sideproject__title">
          {isGames && "Browser Games"}
          {!isGames && "Side Projects"}
          <br />
          <span className="project__subtitle">
            {width <= maxWidthSize && (
              <>
                <span className="swiperIcon">
                  <FontAwesomeIcon
                    className="swiperIconArrow"
                    icon={faArrowLeftLong}
                  />
                  <FontAwesomeIcon className="" icon={faHandPointer} />
                  <FontAwesomeIcon
                    className="swiperIconArrow"
                    icon={faArrowRightLong}
                  />
                </span>
                <span>swipe to see more</span>
              </>
            )}
            {width > maxWidthSize && isGames && "and other small projects"}
            {width > maxWidthSize &&
              !isGames &&
              "made in a short amount of time"}
          </span>
        </h2>
        <div className="customNavigationWrapper">
          <FontAwesomeIcon
            id="projectsPrev"
            className="customNavigationButton"
            icon={faChevronLeft}
          />
          <FontAwesomeIcon
            id="projectsNext"
            className="customNavigationButton"
            icon={faChevronRight}
          />
        </div>
        <Swiper
          {...swiperProps}
          key={`project${allSideGames.length}`}
          className="projectsSwiper swiperSection projects__grid"
          slidesPerView={slidesPerViewSideProjects}
          navigation={{
            prevEl: "#projectsPrev",
            nextEl: "#projectsNext",
          }}
        >
          {allSideGames}
        </Swiper>
      </section>
    </div>
  );
}

export default Projects;
