import React, { useState, useEffect } from "react";
import { NavLink, useLocation } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFile,
  faEnvelope,
  faDice,
  faCode,
} from "@fortawesome/free-solid-svg-icons";
import {
  faGithub,
  faGithubAlt,
  faLinkedin,
  faInstagram,
  faTwitter,
  faMedium,
} from "@fortawesome/free-brands-svg-icons";

import avatar from "../images/avatar.jpg";
import gameAvatar from "../images/gameAvatar.jpg";
import webAvatar from "../images/webAvatar.jpg";
import "../css/intro.css";

function Intro(props) {
  let profession;
  if (!props.profession) {
    let location = useLocation();
    profession =
      location.pathname === "/games"
        ? "games"
        : location.pathname === "/web"
        ? "web"
        : "wonmin";
  } else {
    profession = props.profession;
  }

  let newAvatar =
    profession === "wonmin"
      ? avatar
      : profession === "games"
      ? gameAvatar
      : webAvatar;

  const TitleSection = () => {
    let titleText = "Select a title below to see my works.";
    let description = "";
    let firstButton = {
      link: "/games",
      icon: faDice,
      text: "Game Designer",
      colorClass: "is-bg-tertiary",
    };
    let secondButton = {
      link: "/web",
      icon: faCode,
      text: "Web Developer",
      colorClass: "is-bg-quaternary",
    };

    //change for games or web
    if (profession === "games" || profession === "web") {
      let isGame = profession === "games";

      let tempColor = profession === "games" ? "tertiary" : "quaternary";
      firstButton.link = `/${profession}/resume`;
      firstButton.icon = faFile;
      firstButton.text = "Resume";
      firstButton.colorClass = `is-bg-${tempColor}`;
      secondButton.link = "mailto:1min@unicornwithwings.com";
      secondButton.icon = faEnvelope;
      secondButton.text = "Email me";
      secondButton.colorClass = `is-bg-${tempColor} is-inverted`;

      let tempTitleText = isGame ? "Game Designer" : "Web Developer";
      titleText = (
        <>
          I am a{" "}
          <span className={`is-bg-${tempColor} is-rounded-corner`}>
            &nbsp;{tempTitleText}.&nbsp;
          </span>
        </>
      );

      description = isGame
        ? "I have always loved games ever since I was a little kid. I have designed two board games and hope to leverage my web development experience to make the leap into making video games."
        : `I have developed for the web both professionally and as a hobby for the past ${new Date().getFullYear() -
            2013} years—from creating a domain name SaaS company to developing puzzle/tutorial demos for my board game.`;
    }

    const Button = ({ button }) => {
      if (profession === "games" || profession === "web") {
        return (
          <a
            target="_blank"
            rel="noreferrer noopener"
            href={button.link}
            className={`button noselect ${button.colorClass}`}
          >
            <FontAwesomeIcon icon={button.icon} />
            {button.text}
          </a>
        );
      } else {
        return (
          <NavLink
            to={button.link}
            className={`button noselect ${button.colorClass}`}
          >
            <FontAwesomeIcon icon={button.icon} />
            {button.text}
          </NavLink>
        );
      }
    };

    return (
      <>
        <h3 className="titleText">{titleText}</h3>
        {profession !== "wonmin" && (
          <div className="titlesWrapper">
            <p className="about__desc">{description}</p>
          </div>
        )}
        <div className="titlesWrapper">
          <Button button={firstButton} />
          <Button button={secondButton} />
        </div>
      </>
    );
  };

  let [opacityFromTop, setOpacityFromTop] = useState(1);

  //is the scroll at the top?
  useEffect(() => {
    let handleScroll = () => {
      setOpacityFromTop(Math.max(0, 1 - window.pageYOffset / 600));
    };
    window.addEventListener("scroll", handleScroll, { passive: true });
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [setOpacityFromTop]);

  return (
    <div className="introSection">
      <div className="mainBody spaceBetween row">
        <div className="mainBodyLeft">
          <p className="nameSub">
            Hey!
            <span role="img" aria-label="wave emoji">
              👋
            </span>{" "}
            My name is
          </p>
          <h1 className="name">Wonmin "1min" Lee.</h1>

          <TitleSection />

          <div className="socialLinksWrapper center">
            <a
              target="_blank"
              rel="noreferrer noopener"
              href="mailto:1min@unicornwithwings.com"
              className="link link--icon"
              alt="Email me!"
            >
              <FontAwesomeIcon icon={faEnvelope} />
            </a>
            {(profession === "wonmin" || profession === "games") && (
              <a
                target="_blank"
                rel="noreferrer noopener"
                href="https://pegasusgames.medium.com/list/board-game-marketing-tips-cfcdea7d0c54"
                className="link link--icon"
                alt="Blog"
              >
                <FontAwesomeIcon icon={faMedium} />
              </a>
            )}
            {(profession === "wonmin" || profession === "web") && (
              <>
                <a
                  target="_blank"
                  rel="noreferrer noopener"
                  href="https://github.com/wlee-vendelux"
                  className="link link--icon"
                  alt="Vendelux work Github"
                >
                  <FontAwesomeIcon icon={faGithub} />
                </a>
                <a
                  target="_blank"
                  rel="noreferrer noopener"
                  href="https://github.com/Xcellion"
                  className="link link--icon"
                  alt="Wonmin's personal Github"
                >
                  <FontAwesomeIcon icon={faGithubAlt} />
                </a>
              </>
            )}
            <a
              target="_blank"
              rel="noreferrer noopener"
              href="https://www.linkedin.com/in/wonmin"
              className="link link--icon"
              alt="Wonmin's LinkedIn"
            >
              <FontAwesomeIcon icon={faLinkedin} />
            </a>
            {(profession === "wonmin" || profession === "games") && (
              <>
                <a
                  target="_blank"
                  rel="noreferrer noopener"
                  href="https://www.instagram.com/sysifuscorp"
                  className="link link--icon"
                  alt="Wonmin's Instagram"
                >
                  <FontAwesomeIcon icon={faInstagram} />
                </a>
                <a
                  target="_blank"
                  rel="noreferrer noopener"
                  href="https://www.twitter.com/sysifuscorp"
                  className="link link--icon"
                  alt="Wonmin's Twitter / X"
                >
                  <FontAwesomeIcon icon={faTwitter} />
                </a>
              </>
            )}
          </div>
        </div>
        <div className="mainBodyRight">
          <img className="wonminImage" alt="Wonmin Lee" src={newAvatar}></img>
        </div>
      </div>
      {profession !== "wonmin" && (
        <div
          className="scrollSection is-hidden-desktop"
          style={{ opacity: opacityFromTop }}
        >
          <div className="scrollHorizontalContainer">
            <div id="animatedScroll" className="scrollVerticalContainer"></div>
            <div className="scrollVerticalContainer"></div>
          </div>
          <div className="scrollHorizontalContainer">
            <p className="noselect">Scroll to see my works!</p>
          </div>
        </div>
      )}
    </div>
  );
}

export default Intro;
